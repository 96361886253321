var webtrekkConfig = webtrekkConfig || {
    trackId: '531753817124539',
    trackDomain: 'daten.union-investment.de',
    domain: 'urban-base.eu',
    tabBrowsing: true,
    cookie: '1',
    globalVisitorIds: true,
    linkTrack : 'link',
    contentId : '',
    heatmap :'0'
}
/**
 ************ Don't change anything beyond this line ************
 ********************* Start webtrekk_v4.js *********************
 */
 var webtrekkUnloadObjects=webtrekkUnloadObjects||[],webtrekkLinktrackObjects=webtrekkLinktrackObjects||[],webtrekkHeatmapObjects=webtrekkHeatmapObjects||[],webtrekkV3=function(g){var y=function(a,b){"1"!=f.cookie||(f.optOut||f.deactivatePixel)||f.firstParty();var c=b?b:f.formObject&&"noForm"!=a?"form":"link";!1!=f.beforeUnloadPixel?f.beforeUnloadPixel():"form"==c&&f.executePlugin(f.getPluginConfig("form","before"));var e="";if(f.config.linkId&&(e+="&ct="+f.wtEscape(f.maxlen(f.wtUnescape(f.config.linkId),
 255)))){f.linktrackOut&&(e+="&ctx=1");var d=f.ccParams;"string"==typeof d&&""!=d&&(e+=d)}if(f.wtEp)if(f.wtEpEncoded)e+=f.wtEp;else if(d=f.wtEp,"string"==typeof d&&""!=d)for(var d=d.split(/;/),t=0;t<d.length;t++)if(f.wtTypeof(d[t])){var k=d[t].split(/=/);f.checkSC("custom")&&(k[1]=f.decrypt(k[1]));k[1]=f.wtEscape(k[1]);e+="&"+k[0]+"="+k[1]}"noForm"!=a&&(e+=f.checkFormTracking());""!=e&&(f.quicksend(f.wtEscape(f.contentId.split(";")[0])+",1,"+f.baseparams(),e),f.config.linkId="",f.ccParams="",f.wtEp=
 "");!1!=f.afterUnloadPixel?f.afterUnloadPixel():"form"==c&&f.executePlugin(f.getPluginConfig("form","after"))},q=function(a){var b,c,e=document.getElementById(f.heatmapRefpoint);c=e&&null!==e?b=0:b=-1;if(e&&null!==e&&f.wtTypeof(e.offsetLeft))for(;e;)b+=0<=e.offsetLeft?e.offsetLeft:0,c+=0<=e.offsetTop?e.offsetTop:0,e=e.offsetParent;var d=e=0;a||(a=window.event);if(a.pageX||a.pageY)e=a.pageX,d=a.pageY;else if(a.clientX||a.clientY)if(e=a.clientX,d=a.clientY,f.isIE)if(0<document.body.scrollLeft||0<document.body.scrollTop)e+=
 document.body.scrollLeft,d+=document.body.scrollTop;else if(0<document.documentElement.scrollLeft||0<document.documentElement.scrollTop)e+=document.documentElement.scrollLeft,d+=document.documentElement.scrollTop;a=0;a=f.isIE?document.body.clientWidth:self.innerWidth-16;var t=!0;if(e>=a||!f.sentFullPixel)t=!1;(0<=c||0<=b)&&(e>b&&d>c)&&(e="-"+(e-b),d="-"+(d-c));t&&"1"==f.heatmap&&(f.executePlugin(f.getPluginConfig("heatmap","before")),f.quicksend(f.wtEscape(f.contentId.split(";")[0])+","+e+","+d,"",
 "hm"+(f.fileSuffix?".pl":"")),f.executePlugin(f.getPluginConfig("heatmap","after")))},u=function(){"undefined"!==typeof wt_heatmap?window.setTimeout(function(){wt_heatmap()},1E3):("undefined"===typeof wt_heatmap_retry&&(window.wt_heatmap_retry=0),wt_heatmap_retry++,60>wt_heatmap_retry&&window.setTimeout(function(){u()},1E3))},n=function(){"undefined"!==typeof wt_overlay?window.setTimeout(function(){wt_overlay()},1E3):("undefined"===typeof wt_overlay_retry&&(window.wt_overlay_retry=0),wt_overlay_retry++,
 60>wt_overlay_retry&&window.setTimeout(function(){n()},1E3))},v=function(a,b){var c=f.urlParam(location.href,a,!1),e=f.urlParam(location.href,"wt_t",!1),d=(new Date).getTime(),t=RegExp(b),e=e?parseInt(e)+9E5:d-9E5;return c&&t.test(c)&&e>d?c:!1},p=function(a){if(a&&"2"==a.substring(0,1)){a=parseInt(a.substring(1,11)+"000");a=new Date(a);var b=a.getFullYear()+"",b=b+(9>a.getMonth()?"0":""),b=b+(a.getMonth()+1),b=b+(9>a.getDate()?"0":""),b=b+a.getDate(),b=b+(9>a.getHours()?"0":""),b=b+a.getHours(),b=
 b+(9>a.getMinutes()?"0":"");return b+=a.getMinutes()}return""},h=webtrekkConfig,f=this;g||(g=h);this.defaultAttribute="contentId linkId trackId trackDomain domain linkTrack linkTrackParams linkTrackPattern linkTrackReplace linkTrackDownloads linkTrackIgnorePattern customParameter crmCategory urmCategory customClickParameter customSessionParameter customTimeParameter customCampaignParameter customEcommerceParameter orderValue currency orderId product productCost productQuantity productCategory productStatus couponValue customerId contentGroup mediaCode mediaCodeValue mediaCodeCookie campaignId campaignAction internalSearch customSid customEid cookieDomain cookieEidTimeout cookieSidTimeout forceNewSession xwtip xwtua xwtrq xwteid xwtstt mediaCodeFrames framesetReferrer forceHTTPS secureConfig heatmap pixelSampling form formFullContent formAnonymous disableOverlayView beforeSendinfoPixel afterSendinfoPixel beforeUnloadPixel afterUnloadPixel xlc xlct xlcv ignorePrerendering isIE isOpera isSafari isChrome isFirefox email emailRID emailOptin firstName lastName telefon gender birthday birthdayJ birthdayM birthdayD country city postalCode street streetNumber validation fileSuffix dynamicParameters".split(" ");
 this.cookie=g.cookie?g.cookie:h.cookie?h.cookie:"3";this.optoutName=g.optoutName?g.optoutName:h.optoutName?h.optoutName:"webtrekkOptOut";this.paramFirst=g.paramFirst?g.paramFirst:h.paramFirst?h.paramFirst:"";this.maxRequestLength=g.maxRequestLength?g.maxRequestLength:h.maxRequestLength?h.maxRequestLength:7168;this.plugins=g.plugins&&""!=g.plugins?g.plugins:h.plugins&&""!=h.plugins?h.plugins:"Adobe Acrobat;Windows Media Player;Shockwave Flash;RealPlayer;QuickTime;Java;Silverlight".split(";");"string"==
 typeof this.plugins&&(this.plugins=this.plugins.split(";"));this.heatmapRefpoint=g.heatmapRefpoint?g.heatmapRefpoint:h.heatmapRefpoint?h.heatmapRefpoint:"wt_refpoint";this.linkTrackAttribute=g.linkTrackAttribute?g.linkTrackAttribute:h.linkTrackAttribute?h.linkTrackAttribute:"name";this.delayLinkTrack=g.delayLinkTrack?g.delayLinkTrack:h.delayLinkTrack?h.delayLinkTrack:!1;this.delayLinkTrackTime=g.delayLinkTrackTime?g.delayLinkTrackTime:h.delayLinkTrackTime?h.delayLinkTrackTime:200;this.noDelayLinkTrackAttribute=
 g.noDelayLinkTrackAttribute?g.noDelayLinkTrackAttribute:h.noDelayLinkTrackAttribute?h.noDelayLinkTrackAttribute:!1;this.formAttribute=g.formAttribute?g.formAttribute:h.formAttribute?h.formAttribute:"name";this.formFieldAttribute=g.formFieldAttribute?g.formFieldAttribute:h.formFieldAttribute?h.formFieldAttribute:"name";this.formValueAttribute=g.formValueAttribute?g.formValueAttribute:h.formValueAttribute?h.formValueAttribute:"value";this.formFieldDefaultValue=g.formFieldDefaultValue?g.formFieldDefaultValue:
 h.formFieldDefaultValue?h.formFieldDefaultValue:{};this.formPathAnalysis=g.formPathAnalysis?g.formPathAnalysis:h.formPathAnalysis?h.formPathAnalysis:!1;this.reporturl=g.reporturl?g.reporturl:h.reporturl?h.reporturl:"report2.webtrekk.de/cgi-bin/wt";this.updateCookie=g.updateCookie?g.updateCookie:h.updateCookie?h.updateCookie:!0;this.executePluginFunction=g.executePluginFunction?g.executePluginFunction:h.executePluginFunction?h.executePluginFunction:"";this.eid=this.optOut=this.deactivateRequest=this.deactivatePixel=
 !1;this.cdb={};this.cdbData=[];this.linktrackOut=this.cookieOne=this.sampleCookieString=this.completeRequest=this.lastVisitContact=this.firstVisitContact=!1;this.linktrackNamedlinksOnly=!0;this.sentFullPixel=this.ccParams=!1;this.sentCampaignIds={};this.config=this.browserLang=this.formSubmit=this.formFocus=this.formName=this.formObject=this.gatherFormsP=this.overlayOn=this.heatmapOn=this.trackingSwitchMediaCodeTimestamp=this.trackingSwitchMediaCodeValue=this.trackingSwitchMediaCode=this.wtEpEncoded=
 this.wtEp=!1;this.unloadInstance=webtrekkUnloadObjects.length;this.plugin={};this.heatmapCounter=this.formCounter=this.linkCounter=this.clickCounter=this.pageCounter=0;this.browserLang=!1;"string"==typeof navigator.language?this.browserLang=navigator.language.substring(0,2):"string"==typeof navigator.userLanguage&&(this.browserLang=navigator.userLanguage.substring(0,2));this.jsonPara={ck:["customClickParameter",{}],cp:["customParameter",{}],cs:["customSessionParameter",{}],ce:["customTimeParameter",
 {}],cb:["customEcommerceParameter",{}],vc:["crmCategory",{}],uc:["urmCategory",{}],ca:["productCategory",{}],cc:["customCampaignParameter",{}],cg:["contentGroup",{}],ct:["linkId",""],ov:["orderValue",""],cr:["currency",""],oi:["orderId",""],ba:["product",""],co:["productCost",""],qn:["productQuantity",""],st:["productStatus",""],cd:["customerId",""],is:["internalSearch",""],mc:["campaignId",""],mca:["campaignAction",""]};this.generateDefaultConfig=function(a,b){for(var c=0;c<this.defaultAttribute.length;c++){var e=
 this.defaultAttribute[c];this[e]=a[e]?a[e]:b[e]?b[e]:!1}};this.generateDefaultConfig(g,h);this.campaignAction=g.campaignAction?g.campaignAction:h.campaignAction?h.campaignAction:"click";"undefined"===typeof this.safetag&&(this.safetag=!1);"undefined"===typeof this.safetagInProgress&&(this.safetagInProgress=!1);"undefined"===typeof this.safetagParameter&&(this.safetagParameter={});"undefined"===typeof this.update&&(this.update=function(){});this.saveSendinfoArguments=[];this.safetagTimeoutStarted=
 !1;this.version="430";this.getJSON=function(a){if(a&&"{"==a.charAt(0)&&"}"==a.charAt(a.length-1))try{return eval("("+a+")")}catch(b){}return null};this.parseJSON=function(a,b){for(var c in a){var e=c;if("object"==typeof a[e])"undefined"!=typeof this.jsonPara[e]&&"object"!=typeof this.config[this.jsonPara[e][0]]&&(this.config[this.jsonPara[e][0]]={}),this.parseJSON(a[e],e);else if(b){if(isNaN(parseInt(e))||500>parseInt(e))this.config[this.jsonPara[b][0]][e]=a[e]}else"undefined"!=typeof this.jsonPara[e]&&
 (this.config[this.jsonPara[e][0]]=a[e])}};this.getMappingParam=function(a){var b=a.split(""),c,e,d;for(c=0;c<b.length;c++)if(!isNaN(parseInt(b[c]))){e=c;break}e?(b=a.substr(0,e),d=a.substr(e,a.length-1)):b=a;return{mapping:"undefined"!=typeof this.jsonPara[b]?this.jsonPara[b][0]:!1,index:d?d:!1}};this.getConfig=function(a){for(var b={},c=0;c<this.defaultAttribute.length;c++){var e=this.defaultAttribute[c];b[e]=a?!1:this[e]}return b};this.getRequestCounter=function(a,b){var c=0;"before"==b&&c++;return"link"==
 a?this.linkCounter+=c:"click"==a?this.clickCounter+=c:"page"==a?this.pageCounter+=c:"heatmap"==a?this.heatmapCounter+=c:"form"==a?this.formCounter+=c:0};this.getPluginConfig=function(a,b){return{instance:this,mode:a,type:b,requestCounter:this.getRequestCounter(a,b)}};this.checkAsynchron=function(a,b,c,e){"undefined"!=typeof window[a]?b&&b(!0,c):0>=e?b&&b(!1,c):window.setTimeout(function(){c.checkAsynchron(a,b,c,e-100)},100)};this.loadAsynchron=function(a,b,c,e){this.include(a)&&this.checkAsynchron(b,
 c?c:!1,this,e?e:2E3)};this.include=function(a){if(!document.createElement)return!1;var b=document.getElementsByTagName("head").item(0),c=document.createElement("script");c.setAttribute("language","javascript");c.setAttribute("type","text/javascript");c.setAttribute("src",a);b.appendChild(c);return!0};this.executePlugin=function(a){if(this.executePluginFunction&&"string"===typeof this.executePluginFunction){this.epf=!1;for(var b=this.executePluginFunction.split(";"),c=0;c<b.length;c++)b[c]&&"function"===
 typeof window[b[c]]&&(this.epf=window[b[c]],this.epf(a))}};this.indexOf=function(a,b,c){return a.indexOf(b,c?c:0)};this.wtTypeof=function(a){return"undefined"!==typeof a?1:0};this.wtLength=function(a){return"undefined"!==typeof a?a.length:0};this.getAttribute=function(a,b){return"string"==typeof a.getAttribute(b)?a.getAttribute(b):"object"==typeof a.getAttribute(b)&&"object"==typeof a.attributes[b]&&null!=a.attributes[b]?a.attributes[b].nodeValue:""};this.getTimezone=function(){return Math.round(-1*
 ((new Date).getTimezoneOffset()/60))};this.wtHref=function(){return"undefined"!==typeof window.wtLocationHref?window.wtLocationHref:this.wtLocation().href};this.wtLocation=function(){var a=document.location;if(!document.layers&&document.getElementById)try{a=top.document.location}catch(b){a=document.location}else a=top.document.location;return a};this.checkBrowser=function(){this.isIE=this.indexOf(navigator.appName,"Microsoft")?!1:!0;this.isIE||(this.isOpera=this.indexOf(navigator.appName,"Opera")?
 !1:!0,this.isOpera||(this.isSafari=-1!=navigator.vendor.toLowerCase().indexOf("apple"),this.isChrome=-1!=navigator.vendor.toLowerCase().indexOf("google"),this.isSafari||this.isChrome||(this.isFirefox=-1!=navigator.userAgent.toLowerCase().indexOf("firefox"))))};this.checkBrowser();this.url2contentId=function(a){if(!a)return"no_content";a=/\/\/(.*)/.exec(a);return 1>a.length?"no_content":a[1].split("?")[0].replace(/\./g,"_").replace(/\//g,".").replace(/\.{2,}/g,".").toLowerCase().split(";")[0]};this.contentId=
 g.contentId?g.contentId:this.url2contentId(document.location.href);this.registerEvent=function(a,b,c){a.addEventListener?("webkitvisibilitychange"==b&&this.unregisterEvent(a,b,c),a.addEventListener(b,c,!1)):a.attachEvent&&("beforeunload"!=b&&"webkitvisibilitychange"!=b||this.unregisterEvent(a,b,c),a.attachEvent("on"+b,c))};this.unregisterEvent=function(a,b,c){a.removeEventListener?a.removeEventListener(b,c,!1):a.detachEvent&&a.detachEvent("on"+b,c)};this.maxlen=function(a,b){return a&&a.length>b?
 a.substring(0,b-1):a};this.wtEscape=function(a){try{return encodeURIComponent(a)}catch(b){return escape(a)}};this.wtUnescape=function(a){try{return decodeURIComponent(a)}catch(b){return unescape(a)}};this.decrypt=function(a){var b="";if(a)try{b=this.wtUnescape(a.replace(/([0-9a-fA-F][0-9a-fA-F])/g,"%$1"))}catch(c){}return b};this.checkSC=function(a){if("string"!=typeof this.secureConfig)return!1;for(var b=this.secureConfig.split(";"),c=0;c<b.length;c++)if(b[c]==a)return!0;return!1};this.zeroPad=function(a,
 b){var c="000000000000"+a;return c.substring(c.length-b,c.length)};this.generateEid=function(){return"2"+this.zeroPad(Math.floor((new Date).getTime()/1E3),10)+this.zeroPad(Math.floor(1E6*Math.random()),8)};this.getexpirydate=function(a){var b=new Date,c=b.getTime();b.setTime(c+6E4*a);return b.toUTCString()};this.setCookie=function(a,b,c){var e=location.hostname;-1==e.search(/^[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}$/)&&(e=location.hostname.split("."),e=e[e.length-2]+"."+e[e.length-1]);var d=
 !1;if(this.cookieDomain)for(var f=this.cookieDomain.split(";"),k=0;k<f.length;k++)if(-1!=location.hostname.indexOf(f[k])){e=f[k];d=!0;break}a=d&&"undefined"!=typeof c&&c?a+"="+escape(b)+";domain="+e+";path=/;expires="+this.getexpirydate(c):d?a+"="+escape(b)+";path=/;domain="+e:3>e.split(".")[0].length&&"undefined"!=typeof c&&c?a+"="+escape(b)+";path=/;expires="+this.getexpirydate(c):3>e.split(".")[0].length?a+"="+escape(b)+";path=/":"undefined"!=typeof c&&c?a+"="+escape(b)+";domain="+e+";path=/;expires="+
 this.getexpirydate(c):a+"="+escape(b)+";path=/;domain="+e;document.cookie=a};this.getCookie=function(a){for(var b=document.cookie.split(";"),c=0;c<b.length;c++){var e=b[c].substr(0,b[c].indexOf("=")),d=b[c].substr(b[c].indexOf("=")+1),e=e.replace(/^\s+|\s+$/g,"");if(e==a)return unescape(d)}return""};if(this.optOut=this.getCookie(this.optoutName)?!0:!1)this.deactivatePixel=!0;this.urlParam=function(a,b,c){if(!a||null===a||"undefined"===typeof a)return c;var e=[];0<a.indexOf("?")&&(e=a.split("?")[1].replace(/&amp;/g,
 "&").split("#")[0].split("&"));for(a=0;a<e.length;a++)if(0==e[a].indexOf(b+"="))return this.wtUnescape(e[a].substring(b.length+1).replace(/\+/g,"%20"));return c};this.allUrlParam=function(a,b){if(this.mediaCodeFrames&&""!=this.mediaCodeFrames){for(var c=this.mediaCodeFrames.split(";"),e=0;e<c.length;e++){var d=!1;try{d=eval(c[e])}catch(f){}if(d&&(d!=top&&d.location)&&(d=this.urlParam(d.location.href,a,b),d!=b))return d}return b}c="";try{c=top.location.href}catch(k){c=document.location.href}return this.urlParam(c,
 a,b)};this.plugInArray=function(a,b){if("object"!=typeof a)return!1;for(var c=0;c<a.length;c++){var e=RegExp(a[c].toLowerCase(),"g");if(-1!=b.toLowerCase().search(e))return a[c]}return!1};this.quicksend=function(a,b,c){if(!this.trackDomain||!this.trackId||this.deactivatePixel||this.deactivateRequest)this.deactivateRequest=!1;else{c||(c="wt"+(this.fileSuffix?".pl":""));"undefined"===typeof this.requestTimeout&&(this.requestTimeout=5);this.geid&&(b="&geid="+this.geid+b);this.feid&&(b="&feid="+this.feid+
 b);"1"==this.cookie&&(b="&eid="+this.eid+"&one="+(this.cookieOne?"1":"0")+"&fns="+(this.forceNewSession?"1":"0")+b);"1"!=this.cookie&&(this.wtTypeof(this.cookieEidTimeout)||this.wtTypeof(this.cookieSidTimeout))&&(this.wtTypeof(this.cookieEidTimeout)&&""!=this.cookieEidTimeout&&(b="&cet="+this.cookieEidTimeout+b),this.wtTypeof(this.cookieSidTimeout)&&""!=this.cookieSidTimeout&&(b="&cst="+this.cookieSidTimeout+b));0<this.pixelSampling&&(b+="&ps="+this.pixelSampling);b="&tz="+this.getTimezone()+b;a=
 "//"+this.trackDomain+"/"+this.trackId+"/"+c+"?p="+this.version+","+a+b+"&eor=1";if(this.tabBrowsing)this.sendTabBrowsingPixel(a,c);else if(!this.ignorePrerendering&&this.isChrome&&"undefined"!==typeof document.webkitHidden)if("object"!==typeof this.prerendering&&(this.prerendering=[]),document.webkitHidden){this.prerendering.push(a);var e=this;this.registerEvent(document,"webkitvisibilitychange",function(){e.sendPrerendering()})}else this.sendPixel(a,c);else this.sendPixel(a,c);"hm"!=c&&"hm.pl"!=
 c&&(this.forceNewSession=this.cookieOne=!1,this.sentFullPixel=1)}};this.sendPrerendering=function(){if(!document.webkitHidden){for(var a=0;a<this.prerendering.length;a++)this.sendPixel(this.prerendering[a]);this.prerendering=[]}};this.sendPixel=function(a,b){this.completeRequest=a.split("?")[1];a=this.maxlen(a,this.maxRequestLength);this.isFirefox?this.sendPixelElement(a,b):this.sendPixelImage(a,b)};this.sendPixelImage=function(a,b){var c="https:"==document.location.protocol?"https:":"http:";this.forceHTTPS&&
 (c="https:");0!==a.search(/https:|http:/)&&(a=c+a);if("hm"==b||"hm.pl"==b)a+="&hm_ts="+(new Date).getTime();if("function"!==typeof e)var e=function(){return document.createElement("img")};if("undefined"===typeof d)var d=[];c=d.length;d[c]=new e;d[c].onload=function(){return!1};d[c].src=a};this.createPixelElement=function(a){a=a.replace(/'/g,"%27");var b=document.createElement("div");b.style.width="0px";b.style.height="0px";b.style.backgroundImage="url('"+a+"')";window.setTimeout(function(){b.parentElement.removeChild(b)},
 5E3);return b};this.sendPixelElement=function(a,b){var c="https:"==document.location.protocol?"https:":"http:";this.forceHTTPS&&(c="https:");0!==a.search(/https:|http:/)&&(a=c+a);if("hm"==b||"hm.pl"==b)a+="&hm_ts="+(new Date).getTime();if("undefined"===typeof this.sendPixelObject||null===this.sendPixelObject)(c=document.getElementById("webtrekk-image"))&&null!==c?this.sendPixelObject=c:(this.sendPixelObject=document.createElement("div"),this.sendPixelObject.id="webtrekk-image",this.sendPixelObject.style.width=
 "0px",this.sendPixelObject.style.height="0px",this.sendPixelObject.style.overflow="hidden",c=null,"object"===typeof document.body?c=document.body:"object"===typeof document.getElementsByTagName("body")[0]&&(c=document.getElementsByTagName("body")[0]),c&&null!==c?c.appendChild(this.sendPixelObject):(this.sendPixelObject=null,this.sendPixelImage(a,"wt"+(this.fileSuffix?".pl":""))));null!==this.sendPixelObject&&this.sendPixelObject.appendChild(this.createPixelElement(a))};this.checkCustomParameter=function(a,
 b){var c="";if("object"==typeof a)for(var e in a){var d=e;!isNaN(parseInt(d))&&(this.wtTypeof(a[d])&&"string"===typeof a[d]&&""!==a[d])&&(this.checkSC("custom")&&(a[d]=this.decrypt(a[d])),-1==this.paramFirst.indexOf(b+d+";")&&(c+="&"+b+d+"="+this.wtEscape(a[d])))}return c};this.send=function(a,b,c){if("link"==b||"click"==b)this.config.linkId=a;this.config.contentId=this.config.contentId?this.config.contentId:this.contentId;var e=!b||"link"!=b&&"click"!=b?a?a:this.config.contentId:this.config.contentId;
 e||(e="no_content");a="";var e=this.wtEscape(e)+",1,",e=e+this.baseparams(),d=navigator.plugins.length,f="";if(0<d){for(var k=[],l=0;l<d;l++)navigator.plugins&&"Microsoft Internet Explorer"!=navigator.appName&&(f="Shockwave Flash"==navigator.plugins[l].name?navigator.plugins[l].description:navigator.plugins[l].name,(f=this.plugInArray(this.plugins,f))&&!this.plugInArray(k,f)&&k.push(f));f=k.join("|")}if(this.paramFirst)for(d=this.paramFirst.split(";"),l=0;l<d.length;l++){var m=this.getMappingParam(d[l]),
 k=m.mapping,m=m.index;k&&(m?this.config[k]&&("undefined"!=typeof this.config[k][m]&&this.config[k][m])&&(a+="&"+d[l]+"="+this.wtEscape(this.config[k][m])):this.config[k]&&(a+="&"+d[l]+"="+this.wtEscape(this.config[k])))}if("string"===typeof c&&""!==c)for(var g=c.split(/;/),h=0;h<g.length;h++)this.wtTypeof(g[h])&&(l=g[h].split(/=/),this.checkSC("custom")&&(l[1]=this.decrypt(l[1])),l[1]=this.wtEscape(l[1]),a+="&"+l[0]+"="+l[1]);else{this.wtEpEncoded=!1;c=this.checkCustomParameter(this.config.customParameter,
 "cp");d=this.checkCustomParameter(this.config.customSessionParameter,"cs");k=this.checkCustomParameter(this.config.customTimeParameter,"ce");m=this.checkCustomParameter(this.cdb,"cdb");this.config.couponValue&&(this.config.customEcommerceParameter||(this.config.customEcommerceParameter={}),this.config.customEcommerceParameter[563]=this.config.couponValue);var n=this.checkCustomParameter(this.config.customEcommerceParameter,"cb");this.config.orderValue&&-1==this.paramFirst.indexOf("ov;")&&(a=this.checkSC("order")?
 a+("&ov="+this.wtEscape(this.decrypt(this.config.orderValue))):a+("&ov="+this.wtEscape(this.config.orderValue)));this.config.currency&&-1==this.paramFirst.indexOf("cr;")&&(a=this.checkSC("order")?a+("&cr="+this.wtEscape(this.decrypt(this.config.currency))):a+("&cr="+this.wtEscape(this.config.currency)));this.config.orderId&&-1==this.paramFirst.indexOf("oi;")&&(a+="&oi="+this.wtEscape(this.config.orderId));this.config.product&&(-1==this.paramFirst.indexOf("ba;")&&(a+="&ba="+this.wtEscape(this.config.product)),
 this.config.productCost&&-1==this.paramFirst.indexOf("co;")&&(a+="&co="+this.wtEscape(this.config.productCost)),this.config.productQuantity&&-1==this.paramFirst.indexOf("qn;")&&(a+="&qn="+this.wtEscape(this.config.productQuantity)),a+=this.checkCustomParameter(this.config.productCategory,"ca"),this.config.productStatus&&-1==this.paramFirst.indexOf("st;")&&(a+="&st="+this.wtEscape(this.config.productStatus)));l=v("wt_cd","(.*)");this.config.customerId||(this.config.customerId=l);this.config.customerId&&
 -1==this.paramFirst.indexOf("cd;")&&(a+="&cd="+this.wtEscape(this.config.customerId));a+=this.checkCustomParameter(this.config.crmCategory,"vc");!this.config.birthday&&(this.config.birthdayJ&&this.config.birthdayM&&this.config.birthdayD)&&(this.config.birthday=this.config.birthdayJ+this.config.birthdayM+this.config.birthdayD);this.config.telefon&&(this.config.telefon=this.config.telefon.replace(/\W|_/g,""));this.config.urmCategory||(this.config.urmCategory={});this.config.urmCategory[700]=this.config.email;
 this.config.urmCategory[701]=this.config.emailRID;this.config.urmCategory[702]=this.config.emailOptin;this.config.urmCategory[703]=this.config.firstName;this.config.urmCategory[704]=this.config.lastName;this.config.urmCategory[705]=this.config.telefon;this.config.urmCategory[706]=this.config.gender;this.config.urmCategory[707]=this.config.birthday;this.config.urmCategory[708]=this.config.country;this.config.urmCategory[709]=this.config.city;this.config.urmCategory[710]=this.config.postalCode;this.config.urmCategory[711]=
 this.config.street;this.config.urmCategory[712]=this.config.streetNumber;this.config.urmCategory[713]=this.config.validation;a+=this.checkCustomParameter(this.config.urmCategory,"uc");this.browserLang&&(a+="&la="+this.wtEscape(this.browserLang));a+=this.checkCustomParameter(this.config.contentGroup,"cg");var w="";this.config.campaignId&&(this.config.campaignId in this.sentCampaignIds?this.config.campaignId="ignore%3Dignore":this.sentCampaignIds[this.config.campaignId]=!0,-1==this.paramFirst.indexOf("mc;")&&
 (a+="&mc="+this.wtEscape(this.config.campaignId)),-1==this.paramFirst.indexOf("mca;")&&(a+="&mca="+(this.config.campaignAction?this.config.campaignAction.substring(0,1):"c")));w+=this.checkCustomParameter(this.config.customCampaignParameter,"cc");this.trackingSwitchMediaCode&&(a+="&tmc="+this.wtEscape(this.trackingSwitchMediaCode));this.trackingSwitchMediaCodeValue&&(a+="&tmcv="+this.wtEscape(this.trackingSwitchMediaCodeValue));this.trackingSwitchMediaCodeTimestamp&&(a+="&tmct="+this.wtEscape(this.trackingSwitchMediaCodeTimestamp));
 "object"==typeof wt_ts&&"undefined"!=typeof wt_ts.trackingSwitchMediaCode&&(a+="&tmc="+this.wtEscape(wt_ts.trackingSwitchMediaCode));"object"==typeof wt_ts&&"undefined"!=typeof wt_ts.trackingSwitchMediaCodeValue&&(a+="&tmcv="+this.wtEscape(wt_ts.trackingSwitchMediaCodeValue));"object"==typeof wt_ts&&"undefined"!=typeof wt_ts.trackingSwitchMediaCodeTimestamp&&(a+="&tmct="+this.wtEscape(wt_ts.trackingSwitchMediaCodeTimestamp));var r="";"undefined"!=typeof wt_vt&&(h=wt_vt);this.wtTypeof(h)||(h=this.urlParam(location.href,
 "wt_vt",!1));if(h)for(var p=this.getCookie("wt_vt").split(";"),l=0;l<p.length;l++)-1!=p[l].indexOf(h+"v")&&(r="&wt_vt="+p[l].split("t")[0].split("v")[1]);r&&(a+=r);this.config.internalSearch&&-1==this.paramFirst.indexOf("is;")&&(a+="&is="+this.wtEscape(this.maxlen(this.wtUnescape(this.config.internalSearch),255)));if(this.config.dynamicParameters)for(g in this.config.dynamicParameters)h=g+"",this.config.dynamicParameters[h]&&(a="object"===typeof this.config.dynamicParameters[h]?a+this.checkCustomParameter(this.config.dynamicParameters,
 h):a+("&"+g+"="+this.wtEscape(this.config.dynamicParameters[h])));c&&(a+=c);w&&(a+=w);k&&(a+=k);n&&(a+=n);d&&(a+=d);m&&(a+=m);this.wtTypeof(this.config.customSid)&&""!=this.config.customSid&&(a+="&csid="+this.config.customSid);this.wtTypeof(this.config.customEid)&&""!=this.config.customEid&&(a+="&ceid="+this.config.customEid);this.wtTypeof(this.config.xwtip)&&""!=this.config.xwtip&&(a+="&X-WT-IP="+this.wtEscape(this.config.xwtip));this.wtTypeof(this.config.xwtua)&&""!=this.config.xwtua&&(a+="&X-WT-UA="+
 this.wtEscape(this.config.xwtua));this.wtTypeof(this.config.xwtrq)&&""!=this.config.xwtrq&&(a+="&X-WT-RQ="+this.wtEscape(this.config.xwtrq));this.wtTypeof(this.xwteid)&&""!=this.xwteid&&(a+="&X-WT-EID="+this.wtEscape(this.xwteid),this.xwteid=!1);this.wtTypeof(this.config.xwtstt)&&""!=this.config.xwtstt&&(a+="&X-WT-STT="+this.wtEscape(this.config.xwtstt));!this.sentFullPixel&&this.firstVisitContact&&(a+="&fvc="+this.firstVisitContact);!this.sentFullPixel&&this.lastVisitContact&&(a+="&lvc="+this.lastVisitContact)}a+=
 "&pu="+this.wtEscape(document.location.href.split("#")[0]);this.config.linkId&&this.config.customClickParameter&&(a+=this.checkCustomParameter(this.config.customClickParameter[this.config.linkId]?this.config.customClickParameter[this.config.linkId]:this.config.customClickParameter,"ck"),this.ccParams=!1);this.config.xlc&&this.config.xlct&&(""!=this.config.xlc||""!=this.config.xlct)&&(g="",g=this.config.xlcv?this.getExtLifeCycles(this.config.xlc,this.config.xlct,this.config.xlcv):this.getExtLifeCycles(this.config.xlc,
 this.config.xlct),a+=g);this.config.contentId||this.config.linkId||(this.config.contentId=this.contentId,this.config.linkId="wt_ignore");this.config.linkId?(this.wtEp=a,this.wtEpEncoded=!0,y("noForm",b)):("1"==this.cookie?this.cookieOne&&(a+="&np="+this.wtEscape(f)):a+="&np="+this.wtEscape(f),this.quicksend(e,a))};this.sendinfo_media=function(a,b,c,e,d,f,k,l){this.wtTypeof(wt_sendinfo_media)&&wt_sendinfo_media(a,b,c,e,d,f,k,l,this.unloadInstance)};this.getExtLifeCycles=function(a,b,c){for(var e="",
 d={},f=a.split("|"),k=0;k<f.length;k++){for(var l=f[k].split(";"),m=0;m<l.length;m++)e=0==m?e+this.wtEscape(l[m]):e+l[m],e+=";";e=e.substr(0,e.length-1);e+="|"}e=e.substr(0,e.length-1);d.xlcl=this.wtEscape(a.split("|").length);d.xlct=this.wtEscape(b);"undefined"!=typeof c&&(d.xlcv=this.wtEscape(c));d.xlc=this.wtEscape(e);a="";for(var g in d)a+="&"+k+"="+d[g];return a};this.isOwnDomain=function(a){var b="";if(this.domain)if(0==this.domain.toUpperCase().indexOf("REGEXP:")){if(b=RegExp(this.domain.substring(7),
 "i"),b.test(this.getDomain(a)))return!0}else{b=this.domain.split(";");a=this.getDomain(a);for(var c=0;c<b.length;c++)if(a==b[c])return!0}return!1};this.getDomain=function(a){if("string"!=typeof a)return"";a=this.wtUnescape(a);a=a.split("://")[1];var b=RegExp("^(?:[^/]+://)?([^/:]+)","g");return"undefined"!=typeof a&&(a=a.match(b),a[0])?a[0].toLowerCase():""};this.baseparams=function(){var a=screen.width+"x"+screen.height+","+("Netscape"!=navigator.appName?screen.colorDepth:screen.pixelDepth)+",",
 a=a+(!0==navigator.cookieEnabled?"1,":!1==navigator.cookieEnabled?"0,":-1!=document.cookie.indexOf("=")?"1,":"0,"),a=a+((new Date).getTime()+","),b="0",c=v("wt_ref","(.*)");f.framesetReferrer?b=f.wtEscape(f.framesetReferrer):""!=f.getCookie("wt_ref")?(b=f.wtEscape(f.getCookie("wt_ref")),f.setCookie("wt_ref","",-3600)):c?b=f.wtEscape(c):0<document.referrer.length&&(b=f.wtEscape(document.referrer));f.sentFullPixel?b="2":f.isOwnDomain(b)&&(b="1");c=0;if(!document.layers&&document.getElementById)try{c=
 top.window.innerHeight}catch(e){}else c=top.window.innerHeight;if(!c)try{c=top.document.documentElement.clientHeight}catch(d){}if(!c)try{c=top.document.body.clientHeight}catch(t){}"undefined"==typeof c&&(c=-1);var k;k=0;if(!document.layers&&document.getElementById)try{k=top.window.innerWidth}catch(l){}else k=top.window.innerWidth;if(!k)try{k=top.document.documentElement.clientWidth}catch(m){}if(!k)try{k=top.document.body.clientWidth}catch(g){}"undefined"==typeof k&&(k=-1);c&&c>screen.height&&(c=screen.height);
 k&&k>screen.width&&(k=screen.width);a=a+b+(","+k+"x"+c);return a+=","+(navigator.javaEnabled()?"1":"0")};this.getMediaCode=function(a){if(!a){if(!this.mediaCode)return;a=this.mediaCode}var b=[];a=a.split(";");var c=0,e=[];this.mediaCodeValue&&(b=this.mediaCodeValue.split(";"));for(var d=0;d<a.length;d++){var f=this.allUrlParam(a[d],"");if(this.mediaCodeCookie){var k=!1,l=(this.trackId+"").split(",")[0],m=this.getCookie("wt_mcc_"+l),g;g=a[d]+"_"+f;for(var h=0,n=g.length,p=void 0,r=0;r<n;r++)p=g.charCodeAt(r),
 h=(h<<5)-h+p,h&=h;g=h>1E15-1?"0":h+"";-1===m.indexOf(","+g+",")&&f?(e.push(a[d]+this.wtEscape("="+f)),k=!0):c++;k&&(f="","eid"==this.mediaCodeCookie&&(f=2592E3),this.setCookie("wt_mcc_"+l,m+","+g+",",f))}else"undefined"!==typeof b&&"undefined"!==typeof b[d]&&""!==b[d]?e.push(a[d]+this.wtEscape("="+b[d])):""!==f&&e.push(a[d]+this.wtEscape("="+f))}a.length===c&&0!==a.length&&e.push("ignore%3Dignore");this.config.campaignId=e.join(";")};this.searchContentIds=function(){var a=0,b=0;this.contentIds=this.wtEscape(this.contentId.split(";")[0]);
 do{a++;var c=this.urlParam(document.location.href,"wt_contentId"+a,!1);c&&(this.contentIds+="&wt_contentId"+a+"="+this.wtEscape(c),b++)}while(b>=a)};var A=function(a){var b=f.reporturl;null!==a.match(/^(http[s]?:\/\/)?(report\d+|analytics)\.webtrekk\.(com|de).*$/)&&(b=a.split("/"),b.pop(),b=b.join("/"));return b};this.startHeatmapOrOverlay=function(a,b){this.searchContentIds();this.urlParam(this.wtHref(),"wt_reporter",!1)?this.reporturl=A(this.urlParam(this.wtHref(),"wt_reporter",!1)):this.getCookie("wt_overlayFrame")&&
 (this.reporturl=A(this.getCookie("wt_overlayFrame")));-1===this.reporturl.search(/http|https/)&&(this.reporturl=document.location.protocol+"//"+this.reporturl);this.contentIds&&this.include(this.reporturl+"/"+a+".pl?wt_contentId="+this.contentIds+"&x="+(new Date).getTime())&&("heatmap"==a&&(-1!=navigator.userAgent.indexOf("MSIE 6")&&-1!=navigator.userAgent.indexOf("Windows NT 5.0"))&&alert("Click OK to start heatmap."),"complete"!=document.readyState?this.registerEvent(window,"load",b):b())};this.heatmapOn=
 0<=this.wtHref().indexOf("wt_heatmap=1");this.overlayOn=0<=this.wtHref().indexOf("wt_overlay=1")||0<=document.cookie.indexOf("wt_overlay=1");0<=this.wtHref().indexOf("wt_overlay=0")&&(this.overlayOn=!1,this.setCookie("wt_overlay","",-1));this.heatmapTrackInit=function(){for(var a=!1,b=0;b<webtrekkHeatmapObjects.length;b++)this==webtrekkHeatmapObjects[b]&&(a=!0);!a&&(this.heatmap&&"1"==this.heatmap)&&(webtrekkHeatmapObjects.push(this),this.registerEvent(document,"mousedown",q),this.registerEvent(document,
 "touchstart",q))};this.heatmapTrackInit();this.heatmapOn&&!this.disableOverlayView&&this.startHeatmapOrOverlay("heatmap",u);this.overlayOn&&!this.disableOverlayView&&(this.setCookie("wt_overlay","1"),this.startHeatmapOrOverlay("overlay",n));this.setPixelSampling=function(a){a||(a=this.pixelSampling);for(var b=this.trackId.split(",")[0],c=this.getCookie("wt3_sample").split(";"),e=!1,d=0;d<c.length;d++)-1!=this.indexOf(c[d],b+"|"+a)?e=!0:-1!=this.indexOf(c[d],b+"|")&&(c[d]="");d=6;this.cookieEidTimeout&&
 (d=this.cookieEidTimeout);e?(c=c.join(";"),this.setCookie("wt3_sample",c,43200*d)):(Math&&Math.random&&0==parseInt(Math.random()*a)?c.push(b+"|"+a+"|1"):c.push(b+"|"+a+"|0"),this.setCookie("wt3_sample",c.join(";"),43200*d),c=this.getCookie("wt3_sample"));-1==this.indexOf(c,b+"|"+a+"|1")&&(this.deactivatePixel=!0)};this.pixelSampling&&!this.optOut&&this.setPixelSampling();this.firstParty=function(){for(var a=this.getCookie("wt3_sid").split(";"),b=this.getCookie("wt3_eid").split(";"),c=0===this.cookieEidTimeout?
 this.cookieEidTimeout:this.cookieEidTimeout?this.cookieEidTimeout:6,e=this.trackId.split(",")[0],d=!1,f=!1,k=!1,l=this.generateEid(),m=0;m<a.length;m++)if(-1!=a[m].indexOf(e)){d=m;break}for(m=0;m<b.length;m++)if(-1!=b[m].indexOf(e+"|")){f=m;break}this.eid=v("wt_eid","^[0-9]{19}$");d||(a.push(e),f&&!this.eid&&(this.forceNewSession=!0));f?(this.eid&&(b[f]=e+"|"+this.eid,this.updateCookie=!0),-1==b[f].indexOf("#")&&(b[f]+="#"+l),k=b[f].replace(e+"|","").split("#")[1],this.eid=b[f].replace(e+"|","").split("#")[0],
 b[f]=b[f].replace(/#[0-9]{19}/g,"#"+l),this.updateCookie&&(c?this.setCookie("wt3_eid",b.join(";"),43200*c):this.setCookie("wt3_eid",b.join(";")))):(this.eid||(this.eid=this.generateEid(),this.cookieOne=!0),b.push(e+"|"+this.eid+"#"+l),c?this.setCookie("wt3_eid",b.join(";"),43200*c):this.setCookie("wt3_eid",b.join(";")));this.setCookie("wt3_sid",a.join(";"));d||(this.firstVisitContact=p(this.eid),this.updateCookie&&(this.lastVisitContact=p(k?k:l)))};for(var B=!1,z=0;z<webtrekkUnloadObjects.length;z++)this==
 webtrekkUnloadObjects[z]&&(B=!0);B||webtrekkUnloadObjects.push(this);var C=function(){var a=[],b;for(b in f.safetagParameter){var c=b;if("executePluginFunction"==c)f.executePluginFunction+=f.safetagParameter[c],f.safetagParameter[c]="";else if("object"===typeof f.safetagParameter[c]){"object"!==typeof f[c]&&(f[c]={});for(var e in f.safetagParameter[c]){var d=e;f[c][d]=f.safetagParameter[c][d]}}else f[c]=f.safetagParameter[c],"form"!==c&&"linkTrack"!==c&&"heatmap"!==c&&"tabBrowsing"!==c&&"globalVisitorIds"!==
 c||a.push(c)}for(b=0;b<a.length;b++)switch(a[b]){case "form":f.formTrackInstall();break;case "linkTrack":f.linkTrackInit();break;case "heatmap":f.heatmapTrackInit();break;case "tabBrowsing":f.startTabBrowsing();break;case "globalVisitorIds":f.startGlobalVisitorIds()}f.safetagParameter.pixel=f},D=function(){f.safetagTimeoutStarted=!0;var a=(new Date).getTime()-f.startSafetagTimeoutDate;if(f.safetagInProgress&&a<f.safetag.timeout)window.setTimeout(function(){D()},5);else{f.safetagTimeoutStarted=!1;
 f.safetagInProgress=!1;a>f.safetag.timeout&&(f.xwtstt=f.safetag.timeout+"");for(a=0;a<f.saveSendinfoArguments.length;a++){var b=f.saveSendinfoArguments[a];f.sendinfo(b[0],b[1],b[2],b[3])}f.saveSendinfoArguments=[]}};this.sendinfo=function(a,b,c,e){c=c?c:"page";-1==location.href.indexOf("fb_xd_fragment")&&(this.safetag&&C(),this.config="object"==typeof a?a:this.getConfig(),this.safetagInProgress?(a?f.saveSendinfoArguments.push([this.config,b,c,e]):f.saveSendinfoArguments.push([!1,b,c,e]),this.safetagTimeoutStarted||
 (this.startSafetagTimeoutDate=(new Date).getTime(),window.setTimeout(function(){D()},5))):(this.config.linkId&&(c="click",b||(b=this.config.linkId)),!1!=this.beforeSendinfoPixel?this.beforeSendinfoPixel():this.executePlugin(this.getPluginConfig(c?c:"page","before")),this.safetag&&C(),this.optOut||this.deactivatePixel||("1"==this.cookie?this.firstParty():this.xwteid=v("wt_eid","^[0-9]{19}$")),this.config.campaignId||(!this.mediaCode||"page"!=c||this.config.linkId)||this.getMediaCode(),(""!=this.contentId||
 ""!=b||document.layers)&&this.send(b,c,e),!1!=this.afterSendinfoPixel?this.afterSendinfoPixel():this.executePlugin(this.getPluginConfig(c?c:"page","after"))))};(function(a){var b=function(b,e){var d=this;d.item=e;d.href="undefined"!==typeof e.href?e.href:a.getAttribute(e,"href")?a.getAttribute(e,"href"):"";d.linkIdByNameOrId=a.getAttribute(e,"name")?a.getAttribute(e,"name"):a.getAttribute(e,"id")?a.getAttribute(e,"id"):"";d.linkId="";d.action="link";d.isDownloadFile=!1;d.linktrackOut=!1;d.isInternalLink=
 function(){var b;if(a.linkTrackDownloads){b=d.href.split(".");b=b.pop();for(var c=a.linkTrackDownloads.split(";"),f=0;f<c.length;f++)if(c[f]==b){d.isDownloadFile=!0;break}}d.linktrackOut=a.domain&&!a.isOwnDomain(d.href);if(d.isDownloadFile||"_blank"===e.target)d.action="click";b=d.href;var c=b.toLowerCase(),f=b.split("#")[0],m=document.location,g=d.item,h=a.getAttribute,n=h(g,"onclick"),p=h(g,"onmousedown"),g=h(g,"ontouchstart");b=a.noDelayLinkTrackAttribute?!!a.getAttribute(e,a.noDelayLinkTrackAttribute):
 !(b&&!(0===c.indexOf("javascript:")||0===c.indexOf("#")||"click"===d.action||f==m.href.split("#")[0]&&-1!==b.indexOf("#")||f==m.pathname.split("#")[0]&&-1!==b.indexOf("#")||n&&-1!==n.search(/return false[;]?$/)||p&&-1!==p.search(/return false[;]?$/)||g&&-1!==g.search(/return false[;]?$/)));return b};d.getCCParams=function(){var b="";if(a.config.customClickParameter){var c="undefined"!==typeof a.config.customClickParameter[d.linkIdByNameOrId]?a.config.customClickParameter[d.linkIdByNameOrId]:!1;c||
 (c=a.config.customClickParameter);var e,f;for(f in c)e=f,!isNaN(parseInt(e))&&("string"===typeof c[e]&&c[e])&&(a.checkSC("custom")&&(c[e]=a.decrypt(c[e])),b+="&ck"+e+"="+a.wtEscape(c[e]))}return b};d.setJSONParams=function(){d.linkId||(d.linkId=a.getAttribute(e,a.linkTrackAttribute));null!==a.getJSON(d.linkId)&&(a.parseJSON(a.getJSON(d.linkId)),d.linkId=a.config.linkId)};d.getLinkId=function(){d.linkId=a.getAttribute(e,a.linkTrackAttribute);d.setJSONParams();if("link"==a.linkTrack){var b=d.href.indexOf("//");
 d.href=0<=b?d.href.substr(b+2):d.href;a.linkTrackPattern&&(a.linkTrackReplace||(a.linkTrackReplace=""),d.href=d.href.replace(a.linkTrackPattern,a.linkTrackReplace));d.linkId=(d.linkId?d.linkId+".":"")+d.href.split("?")[0].split("#")[0].replace(/\//g,".");b=[];a.linkTrackParams&&(b=a.linkTrackParams.replace(/;/g,",").split(","));for(var c=0;c<b.length;c++){var f=a.urlParam(d.href,b[c],"");f&&(d.linkId+="."+b[c]+"."+f)}}return d.linkId}};a.linkTrackObject=new function(){var c=this;c.triggerObjectName=
 "__"+(new Date).getTime()+"_"+parseInt(1E3*Math.random());var e=function(b,d){var e=d[c.triggerObjectName];a.config=a.getConfig(!0);a.config.customClickParameter=a.customClickParameter;a.ccParams=e.getCCParams();var f=a.config.linkId=e.getLinkId();a.linktrackOut=e.linktrackOut;a.sendinfo(a.config,f,e.action)},d=function(b){a.registerEvent(b,"click",function(d){if(d.which&&1==d.which||d.button&&1==d.button)a.delayLinkTrack&&("function"===typeof d.preventDefault&&!b[c.triggerObjectName].isInternalLink())&&
 (d.preventDefault(),window.setTimeout(function(){document.location.href=b.href},a.delayLinkTrackTime)),e(d,b)})};c.linkTrackInit=function(){if(a.linkTrack&&("link"==a.linkTrack||"standard"==a.linkTrack)){for(var e=!1,f=0;f<webtrekkLinktrackObjects.length;f++)a==webtrekkLinktrackObjects[f]&&(e=!0);e||webtrekkLinktrackObjects.push(a);e=0;for(f=document.links.length;e<f;e++){var l=document.links[e],g=a.getAttribute(l,a.linkTrackAttribute),h=a.getAttribute(l,"href");(a.linkTrackIgnorePattern&&h&&-1==
 h.search(a.linkTrackIgnorePattern)||!a.linkTrackIgnorePattern)&&("undefined"===typeof l[c.triggerObjectName]&&(g||"link"==a.linkTrack))&&(l[c.triggerObjectName]=new b(c,l),d(l))}}};c.linkTrackInit()};a.linkTrackInstall=a.linkTrackObject.linkTrackInit;a.linkTrackInit=a.linkTrackObject.linkTrackInit})(f);(function(a,b){var c=function(a,b,c){var f=null,l=b.type,g=a.getFormFieldName(b),h=a.getFormFieldValue(b);this.close=function(){null!==f&&(h=a.getFormFieldValue(b),"radio"!==l&&(window.clearInterval(f),
 f=null,a.formFieldData[g][c]=[l,h]),a.formFieldDataPathAnalysis.push([g,l,h]))};this.start=function(n){null===f&&(f=window.setInterval(function(){"undefined"!==typeof b&&(b&&null!==b&&"undefined"!==typeof a.formFieldData[g])&&("string"!==typeof a.formFieldData[g][0]&&"string"!==typeof a.formFieldData[g][a.formFieldData.length-1])&&(h=a.getFormFieldValue(b),a.formFieldData[g][c]=[l,h])},50),n||delete a.formFieldDataUnused[g])}};a.formTrackObject=new function(b){var d=this,f=a.wtTypeof(window.onbeforeunload)?
 "beforeunload":"unload";d.formObject=!1;d.formFocus=!1;d.formName=!1;d.form=a.form;d.formFieldData={};d.formFieldDataUnused={};d.formFieldDataPathAnalysis=[];d.triggerObjectName="__"+(new Date).getTime()+"_"+parseInt(1E3*Math.random());var k=function(a){return"hidden"!=a&&"button"!=a&&"image"!=a&&"reset"!=a&&"submit"!=a&&"fieldset"!=a},l=function(a){return"select-multiple"!=a&&"select-one"!=a&&"checkbox"!=a&&"radio"!=a},g=function(){h();if(d.formObject){var c=a.getAttribute(d.formObject,a.formAttribute);
 d.formName=c?c:a.contentId.split(";")[0];for(var c=0,l=d.formObject.elements,g=l.length;c<g;c++){var m=l[c],s=d.getFormFieldName(m);if(k(m.type)&&s&&null!==s){"undefined"===typeof d.formFieldData[s]&&(d.formFieldData[s]=[],d.formFieldDataUnused[s]=[]);var x=d.getFormFieldValue(m);d.formFieldData[s].push([m.type,x]);d.formFieldDataUnused[s].push([m.type,x]);s=d.formFieldData[s].length-1;"radio"===m.type&&m.checked&&n(m,!0,s);(function(b,c){a.registerEvent(b,"focus",function(){n(b,!1,c)});a.registerEvent(b,
 "blur",function(){k(b.type)&&(d.formObject&&b&&b&&"undefined"!==typeof b[d.triggerObjectName])&&(b[d.triggerObjectName].close(),"radio"!==b.type&&delete b[d.triggerObjectName])})})(m,s)}}a.registerEvent(d.formObject,"submit",p);a.registerEvent(window,f,b)}},h=function(){if(d.form&&!d.formObject)for(var b=document.forms,c=0,e=b.length;c<e;c++){var f=b[c];if(a.wtTypeof(f.elements.wt_form)){d.formObject=f;break}}},n=function(a,b,e){k(a.type)&&d.formObject&&(b||(d.formFocus=a),"undefined"===typeof a[d.triggerObjectName]&&
 (a[d.triggerObjectName]=new c(d,a,e),a[d.triggerObjectName].start(b)))},p=function(b){!d.form||b.target!==d.formObject&&b.srcElement!==d.formObject||(a.formSubmit=!0)},w=function(b){var c=[];a.formFullContent&&(c=a.formFullContent.split(";"));if(a.formAnonymous||l(b.type)){for(var e=0;e<c.length;e++)if(c[e]==d.getFormFieldName(b))return!1;return!0}return!1},r=function(b,c){c||(c=b);var d=a.getAttribute(c,a.formValueAttribute).replace(/[\.|;|\|]/g,"_");return l(b.type)?a.maxlen(a.wtUnescape(c.value),
 110):w(b)?"anon":a.maxlen(a.wtUnescape(d),110)},q=function(a,b,c,e){var f=a.replace(/[\.|;|\|]/g,"_")+".",f=f+(b+"|")+(c+"|");return f=e?f+"0":f+(d.formFocus&&d.getFormFieldName(d.formFocus)===a?"1":"0")},u=function(a,b){for(var c=[],d=0,e=b.length;d<e;d++)if("undefined"!==typeof a[b[d]])if("select-multiple"==a[b[d]][0])for(var f=a[b[d]][1].split("|"),k=0,l=f.length;k<l;k++)c.push(q(b[d],a[b[d]][0],f[k]));else c.push(q(b[d],a[b[d]][0],a[b[d]][1]));return c.join(";")},x=function(a){for(var b in a){var c=
 b+"";if(1<a[c].length){for(var d=!1,e=0;e<a[c].length;e++)if("empty"!==a[c][e][1]){a[c]=a[c][e];d=!0;break}if(d)continue}a[c]=a[c][0]}},v=function(){if(!d.formObject)return"";x(d.formFieldData);x(d.formFieldDataUnused);var b=[],c;c=[];if(a.wtTypeof(d.formObject.elements.wt_fields)){var e=d.formObject.elements.wt_fields.value;e&&(c=e.split(";"))}if(0>=c.length)for(var f in d.formFieldData)e=f,"string"===typeof e&&e&&c.push(e);f=!1;if(a.formPathAnalysis){(e=u(d.formFieldDataUnused,c))&&b.push(e);for(var e=
 0,k=d.formFieldDataPathAnalysis,l=k.length;e<l;e++){var g;a:{g=0;for(var h=c.length;g<h;g++)if(k[e][0]===c[g]){g=!0;break a}g=!1}g&&(f=!0,b.push(q(k[e][0],k[e][1],k[e][2],!0)))}f&&(c=b[b.length-1],c=c.substr(0,c.length-1),b[b.length-1]=c+"1")}else return u(d.formFieldData,c);return b.join(";")};d.getFormFieldName=function(b){var c=b.name;a.formFieldAttribute&&(c="",(b=a.getAttribute(b,a.formFieldAttribute))||null!==b)&&(c=b);return c};d.getFormFieldValue=function(b){var c=b.type,e="";if("select-multiple"==
 c){for(var e=[],f=0,k=b.options,l=k.length;f<l;f++)k[f].selected&&e.push(r(b,k[f]));0>=e.length&&e.push("empty");e=e.join("|")}else"select-one"==c?(e="",-1!==b.selectedIndex&&((e=r(b,b.options[b.selectedIndex]))&&null!==e||(e="empty"))):"checkbox"==c||"radio"==c?(e="",b.checked?(e=r(b))||(e="checked"):e="empty"):"hidden"!=c&&("button"!=c&&"image"!=c&&"reset"!=c&&"submit"!=c)&&(f=(e=r(b))?"filled_out":"empty",w(b)||(f=e),k=d.getFormFieldName(b),"undefined"!==typeof a.formFieldDefaultValue[k]&&a.formFieldDefaultValue[k]==
 e&&"empty"!==f&&(f="empty"),f&&null!==f||(f="empty"),e=f);return w(b)&&"select-multiple"!==c&&"empty"!==e&&"filled_out"!==e?"anon":e};d.formTrackInstall=function(a){d.formObject=a?a:document.forms[0]?document.forms[0]:!1;d.formObject&&(d.form="1",g())};d.getFormTrackingData=function(){var b="";if(d.formObject){var c=v();c&&(b+="&fn="+a.wtEscape(d.formName)+"|"+(a.formSubmit?"1":"0"),b+="&ft="+a.wtEscape(c));a.formSubmit=!1;d.formName=!1;d.formObject=!1;d.formFocus=!1;d.formFieldData={};d.formFieldDataUnused=
 {};d.formFieldDataPathAnalysis=[]}return b};d.sendFormRequest=function(){"1"!==a.cookie||(a.optOut||a.deactivatePixel)||a.firstParty();!1!==a.beforeUnloadPixel?a.beforeUnloadPixel():a.executePlugin(a.getPluginConfig("form","before"));var b=d.getFormTrackingData();b&&a.quicksend(a.wtEscape(a.contentId.split(";")[0])+",1,"+a.baseparams(),b);!1!==a.afterUnloadPixel?a.afterUnloadPixel():a.executePlugin(a.getPluginConfig("form","after"))};d.form&&"1"==d.form&&g()}(b);a.formTrackInstall=a.formTrackObject.formTrackInstall;
 a.formTrackInit=a.formTrackObject.formTrackInstall;a.sendFormRequest=a.formTrackObject.sendFormRequest;a.checkFormTracking=a.formTrackObject.getFormTrackingData})(f,function(){y("form")});(function(a){a.tabBrowsing="undefined"!==typeof g.tabBrowsing?g.tabBrowsing:"undefined"!==typeof h.tabBrowsing?h.tabBrowsing:!1;var b=function(){var a="",b="",c=!1,e=!0,d=0,f=null,g=function(a){},h=function(){},n=function(a,b,c){a.addEventListener?a.addEventListener(b,c,!1):a.attachEvent&&a.attachEvent("on"+b,c)},
 p=function(){var a=1E3;f=window.setInterval(function(){0>=a&&(window.clearInterval(f),c||(e=!0,d++,g(d)));a-=5},5)};this.isPageHidden=function(){return c};this.isPageVisible=function(){return e};this.setVisibleCall=function(a){g=a};this.setHiddenCall=function(a){h=a};"undefined"!==typeof document.hidden?(a="hidden",b="visibilitychange"):"undefined"!==typeof document.mozHidden?(a="mozHidden",b="mozvisibilitychange"):"undefined"!==typeof document.msHidden?(a="msHidden",b="msvisibilitychange"):"undefined"!==
 typeof document.oHidden?(a="oHidden",b="ovisibilitychange"):"undefined"!==typeof document.webkitHidden&&(a="webkitHidden",b="webkitvisibilitychange");a&&(c=document[a],(e=!c)&&d++,function(){n(document,b,function(){(c=document[a])?(window.clearInterval(f),e=!1,h()):p()})}())},c=[],e=[],d=null,f=function(){d=new b;d.setVisibleCall(function(b){if(1<b&&0<e.length){var d=e[0].split("&");b-=1;d[1]="tb="+b+"&cp770="+b+"&"+d[1];a.sendPixel(d.join("&"),e[1])}for(var f=0,g=c.length;f<g;f++)d=c[f][0],b=c[f][1],
 a.sendPixel(d,b),"wt"===b&&-1===d.search(/&(ct|fn|ft)=.+&/)&&(e=[d,b]);c=[]})};a.tabBrowsing&&f();a.sendTabBrowsingPixel=function(b,f){d.isPageVisible()?(a.sendPixel(b,f),"wt"===f&&-1===b.search(/&(ct|fn|ft)=.+&/)&&(e=[b,f])):c.push([b,f])};a.startTabBrowsing=function(){null===d&&(a.tabBrowsing=!0,f())}})(f);(function(a){a.geid=!1;a.feid=!1;a.globalVisitorIds="undefined"!==typeof g.globalVisitorIds?g.globalVisitorIds:"undefined"!==typeof h.globalVisitorIds?h.globalVisitorIds:!1;var b=!1,c=function(){b=
 !0;var c=a.getCookie("wt_geid"),d=a.getCookie("wt_feid");d&&-1!==d.search(/^[0-9a-f]{32}$/)&&(a.feid=d);c?"68934a3e9455fa72420237eb"!==c&&(a.geid=c):a.loadAsynchron(document.location.protocol+"//"+a.trackDomain+"/js/webtrekk_geid.min.js","wt_geid",function(a,b){a&&wt_geid(b,window,document,window.navigator)},2E3)};a.globalVisitorIds&&c();a.startGlobalVisitorIds=function(){b||(a.globalVisitorIds=!0,c())}})(f);(function(a){a.cookieManager=function(a,c,e){this.name=a;this.keySeperator="~";this.fieldSeparator=
 "#";this.durationSeperator="|";this.found=!1;this.expires=c?c:!1;this.accessPath=e?e:"/";this.rawValue="";this.fields=[];this.fieldsDuration=[];var d=function(a){try{return decodeURIComponent(a)}catch(b){return unescape(a)}},f=function(a){try{return encodeURIComponent(a)}catch(b){return escape(a)}};this.read=function(){this.rawValue=null;this.found=!1;for(var a=document.cookie.split(";"),b=0;b<a.length;b++){var c=a[b].substr(0,a[b].indexOf("=")),e=a[b].substr(a[b].indexOf("=")+1),c=c.replace(/^\s+|\s+$/g,
 "");c==this.name&&(this.rawValue=e,this.found=!0)}if(null!==this.rawValue){a=b=0;do a=this.rawValue.indexOf(this.fieldSeparator,b),-1!=a&&(b=this.rawValue.substring(b,a).split(this.durationSeperator),c=b[0].split(this.keySeperator),this.fields[c[0]]=d(c[1]),this.fieldsDuration[c[0]]=parseInt(d(b[1])),b=a+1);while(-1!==a&&a!==this.rawValue.length-1)}return this.found};this.getSize=function(){var a=(new Date).getTime(),b="",c;for(c in this.fields){var d=c+"";this.fieldsDuration[d]>=a&&(b+=f(d)+this.keySeperator+
 f(this.fields[d])+this.durationSeperator+f(this.fieldsDuration[d])+this.fieldSeparator)}return b.length};this.write=function(){var a=(new Date).getTime(),b=!0,c=this.name+"=",d;for(d in this.fields){var e=d+"";this.fieldsDuration[e]>=a&&(c+=f(e)+this.keySeperator+f(this.fields[e])+this.durationSeperator+f(this.fieldsDuration[e])+this.fieldSeparator,b=!1)}a=b?-99999:this.expires;""!==a&&"number"===typeof a&&(b=new Date,b.setTime((new Date).getTime()+864E5*a),c+="; expires="+b.toGMTString());null!==
 this.accessPath&&(c+="; PATH="+this.accessPath);a=location.hostname;-1==a.search(/^[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}$/)&&(a=location.hostname.split("."),a=a[a.length-2]+"."+a[a.length-1]);document.cookie=c+("; DOMAIN="+a)};this.remove=function(){this.expires=-10;this.write();return this.read()};this.get=function(a){var b=(new Date).getTime();return this.fieldsDuration[a]>=b?this.fields[a]:""};this.set=function(a,b,c,d,e){c||(c=31536E3);d||(d="last");var f=(new Date).getTime();if("first"==
 d&&""!==this.fields[a]&&null!==this.fields[a]&&this.fieldsDuration[a]>=f)return this.fields[a];this.fields[a]=b;this.fieldsDuration[a]=f+1E3*parseInt(c);e||this.write();return b};this.prepare=function(a,b,c,d){return this.set(a,b,c,d,!0)};this.read()}})(f)};
 if("object"===typeof webtrekkConfig&&"object"===typeof webtrekkConfig.safetag&&-1===document.cookie.indexOf("wt_r=1")){var wts=wts||[],wt_safetagConfig={async:"undefined"!==typeof webtrekkConfig.safetag.async?webtrekkConfig.safetag.async:!0,timeout:"undefined"!==typeof webtrekkConfig.safetag.timeout?webtrekkConfig.safetag.timeout:2E3,safetagDomain:"undefined"!==typeof webtrekkConfig.safetag.safetagDomain?webtrekkConfig.safetag.safetagDomain:!1,safetagId:"undefined"!==typeof webtrekkConfig.safetag.safetagId?
 webtrekkConfig.safetag.safetagId:!1,customDomain:"undefined"!==typeof webtrekkConfig.safetag.customDomain?webtrekkConfig.safetag.customDomain:!1,customPath:"undefined"!==typeof webtrekkConfig.safetag.customPath?webtrekkConfig.safetag.customPath:!1,option:"undefined"!==typeof webtrekkConfig.safetag.option?webtrekkConfig.safetag.option:{}};(function(g,y){var q=function(g){try{return encodeURIComponent(g)}catch(f){return escape(g)}},u=document.location.protocol;if("http:"==u||"https:"==u){var n="";g.customDomain&&
 g.customPath?n=u+"//"+g.customDomain+"/"+g.customPath:g.safetagDomain&&(g.safetagId&&-1!==g.safetagId.search(/^\d+$/))&&(n=u+"//"+g.safetagDomain+"/resp/api/get/"+g.safetagId+"?url="+q(document.location.href));if(n){for(var v in g.option)n+="&"+v+"="+q(g.option[v]);webtrekkV3.prototype.safetag=g;webtrekkV3.prototype.safetagInProgress=!0;webtrekkV3.prototype.safetagParameter={};webtrekkV3.prototype.update=function(){};window.wts=y;window.safetagLoaderHandler=function(g,f){if(g&&f)if("onerror"==f)webtrekkV3.prototype.safetagInProgress=
 !1;else if("onload"==f){if("undefined"!==typeof wt_r&&!isNaN(wt_r)){var n=new Date;document.cookie="wt_r=1;path=/;expires="+n.toUTCString(n.setTime(n.getTime()+1E3*parseInt(wt_r)))}webtrekkV3.prototype.safetagInProgress=!1}else"onreadystatechange"!=f||"loaded"!=g.readyState&&"complete"!=g.readyState||(g.onreadystatechange=null,g.onload(p))};if(g.async||"complete"===document.readyState||"interactive"===document.readyState){var q=document.getElementsByTagName("script")[0],p=document.createElement("script");
 p.async=g.async;p.type="text/javascript";p.onerror=function(){safetagLoaderHandler(p,"onerror")};p.onload=function(){safetagLoaderHandler(p,"onload")};p.onreadystatechange=function(){safetagLoaderHandler(p,"onreadystatechange")};p.src=n;q.parentNode.insertBefore(p,q)}else n='<script type="text/javascript" src="'+n+'" onerror="safetagLoaderHandler(this,\'onerror\')"',n+=" onload=\"safetagLoaderHandler(this,'onload')\"",n+=" onreadystatechange=\"safetagLoaderHandler(this,'onreadystatechange')\"",n+=
 ">\x3c/script>",document.write(n)}}})(wt_safetagConfig,wts)};
 
 /** End webtrekk_v4.js */

// sendInfo

if (typeof(webtrekkV3) == 'function') {
    var wt = new webtrekkV3(webtrekkConfig);
    wt.sendinfo();
}